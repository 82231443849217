<template>
  <v-row class="ma-0 height-100" v-if="designerInfo" style="padding: 40px 10px">
    <v-col class="py-0 px-5">
      <v-row class="ma-0">
        <v-avatar size="100" :color="designerInfo.avatarUrl ? 'transparent' : 'primary'" class="designer-avatar mr-5">
          <img
            v-if="designerInfo.avatarUrl"
              :src="designerInfo.avatarUrl"
            >
          <span v-else class="fz-32 font-weight-bold white--text"> {{ designerInfo.businessInitials }}</span>
        </v-avatar>
        <v-col class="pa-0 d-flex flex-column align-start">
          <p class="fz-22 mb-0 font-weight-bold text-black lh-20 mt-2">{{ designerInfo.businessName }}</p>
          <span class="fz-14 lh-20 font-weight-bold text-dgray">Illustrator</span>
          <v-chip style="padding: 4px 10px; height: 28px;" color="#7552CC" class="mt-3">
            <span class="fz-14 lh-20">Senior</span>
          </v-chip>
        </v-col>
      </v-row>
      <v-divider style="border-color: #E3E6EF; margin: 30px 0;"></v-divider>
      <v-list class="pa-0">
        <v-list-item
          class="px-0 width-287"
         dense>
          <v-icon size="20" class="mr-3">$tme</v-icon>
          <a :href="designerInfo.contact" class="fz-14 text-decoration-underline one-line">{{ designerInfo.contact }}</a>
        </v-list-item>
        <v-list-item
          class="px-0 width-287"
         dense>
          <v-icon size="20" class="mr-3">$mail</v-icon>
          <a :href="designerInfo.gmail" class="fz-14 text-decoration-underline one-line">{{ designerInfo.gmail }}</a>
        </v-list-item>
        <v-list-item
          class="px-0 width-287"
         dense>
          <v-icon size="20" class="mr-3">$designTool</v-icon>
          <a :href="designerInfo.portfolio" class="fz-14 text-decoration-underline one-line">{{ designerInfo.portfolio }}</a>
        </v-list-item>
      </v-list>
      <v-divider style="border-color: #E3E6EF; margin: 30px 0;"></v-divider>
      <v-sheet color="transparent" elevation="0">
        <v-chip
          label
          outlined
          v-for="tool in designerInfo.tools"
          :key="tool.id"
          class="fz-12 font-weight-medium text-black rounded-lg"
          style="margin-right: 10px; margin-bottom: 10px; height: 40px; border: 1px solid #E3E6EF;"
        >
            <img
              class="mr-2"
              :src="tool.iconUrl"
              @error="tool.name = 'Figma'"
              >
          {{ tool.name === 'AP' ? 'Adobe Photoshop'
            : tool.name === 'AE' ? 'After Effects'
            : tool.name === 'AI' ? 'Adobe Illustrator' : tool.name }}
        </v-chip>
      </v-sheet>
    </v-col>
    <v-col class="py-0 px-5">
      <v-card elevation="0" :color="designerInfo.statistics.rank > 4 ? '#D8F7EB' : designerInfo.statistics.rank > 2 ? '#FFF4D9' : '#FEF0F0'" style="padding: 30px" class="rounded-4 mb-5">
        <span class="fz-16 lh-22 fw-600 text-black">Designer rating</span>
        <v-row class="mx-0 my-8 align-center">
          <v-img width="32" max-width="32" max-height="32" height="32" :src="designerInfo.statistics.rank > 4 ? require('@/assets/img/rate5.png') : designerInfo.statistics.rank > 2 ? require('@/assets/img/rate3.png') : require('@/assets/img/rate1.png')" class="mr-3"></v-img>
          <span class="fz-24 lh-22 font-weight-bold text-black">{{ designerInfo.statistics.rank }}</span>
        </v-row>
        <p class="ma-0 fz-14 lh-22 font-weight-bold text-black">{{ designerInfo.statistics.qualityRank }} <span class="font-weight-regular">Design quality</span></p>
        <p class="ma-0 fz-14 lh-22 font-weight-bold text-black">{{ designerInfo.statistics.collaborationRank }} <span class="font-weight-regular">Collaboration with designer</span></p>
      </v-card>
      <v-card elevation="0"  style="padding: 30px; border: 1px solid #E3E6EF;" class="rounded-4">
        <span class="fz-16 lh-22 fw-600 text-black">Projects completed on time</span>
        <v-row style="margin: 30px 0" class="align-center">
          <v-icon :class="designerInfo.statistics.onTimeDelivery > 60 ? 'stroke-green' : designerInfo.statistics.onTimeDelivery > 30 ? 'stroke-yellow' : 'stroke-red'" size="36" style="margin-right: 10px">$deadline</v-icon>
          <span class="fz-24 lh-22 font-weight-bold text-black">{{ designerInfo.statistics.onTimeDelivery }}%</span>
        </v-row>
        <v-row class="ma-0">
          <v-progress-linear
              class="rounded-xl"
              height="12"
              :color="designerInfo.statistics.onTimeDelivery > 60 ? '#3BD599' : designerInfo.statistics.onTimeDelivery > 30 ? '#FD9000' : '#E30100'"
              :style="{'border': '4px solid ' + (designerInfo.statistics.onTimeDelivery > 60 ? 'rgba(59,213,153,0.06)' : designerInfo.statistics.onTimeDelivery > 30 ? 'rgba(253,144,0,0.06)' : 'rgba(227,1,0,0.06)')}"
              rounded
              :value="designerInfo.statistics.onTimeDelivery || 0"
            ></v-progress-linear>
        </v-row>
      </v-card>
    </v-col>
    <v-col class="pa-0 d-flex flex-column">
      <span class="fz-16 ml-5 fw-600 lh-22 text-black">Order History</span>
      <v-col class="py-0 px-5 height-100 scroll" style="margin-top: 30px">
        <v-card v-for="order in orders"
                :key="order.order.id"
                outlined
                class="rounded-4 mb-5 border-lightgray"
                @click.native="getOrder(order.id)"
          >
          <v-col class="py-4 px-5">
            <v-row class="ma-0 mb-2">
              <v-chip
                class="ma-0 mr-1 my-auto white--text"
                x-small
                style="height: 18px"
                :style="color(order.order.status.value)"
                label
              >
                {{ order.order.status.label }}
              </v-chip>
              <v-chip
                class="ma-0 my-auto white--text"
                x-small
                color="#EB5757"
                style="box-shadow: 0px 3px 6px rgba(235, 87, 87, 0.2); height: 18px"
                label
                v-if="!order.order.paymentDone"
              >
                unpaid
              </v-chip>
            </v-row>
            <v-row class="ma-0 fz-14 mb-2">
              <span class="text-gray">Project: <b class="text-black font-weight-medium">{{ order.order.name }}</b></span>
            </v-row>
            <v-row class="ma-0 fz-14 mb-2">
              <span class="text-gray">Completed: <b class="text-black font-weight-medium">{{ order.order.dateEnd ? formatDate(order.order.dateEnd) : '' }}</b></span>
            </v-row>
            <v-row class="ma-0 fz-14 mb-2">
              <span class="text-gray">Manager: <b class="text-black font-weight-medium">{{ order.order.manager.businessName }}</b></span>
            </v-row>
            <v-row class="ma-0 fz-14 mb-2">
              <span class="text-gray">Client: <b class="text-black font-weight-medium">{{ order.order.client ? order.order.client.name : '' }}</b></span>
            </v-row>
            <v-row class="ma-0 fz-14">
              <span class="text-gray">Cost: <b class="text-black font-weight-medium">{{ +order.cost | numberFormat }}{{ order.order.currency === 'USD' ? '$' :
          order.order.currency === 'EUR' ? '€' :
          order.order.currency === 'RUB' ? '₽' : '' }}</b></span>
            </v-row>
            <v-row v-if="order.order.rating.collaboration || order.order.rating.quality" class="ma-0 fz-14 mt-2">
              <v-tooltip v-if="order.order.rating.collaboration" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    v-on="on"
                    class="mr-3"
                    max-width="33"
                    max-height="33"
                    :src="order.order.rating.collaboration === 'GOOD' ? require('@/assets/img/rate5.png') : order.order.rating.collaboration === 'OK' ? require('@/assets/img/rate3.png') : require('@/assets/img/rate1.png')"></v-img>
                </template>
                <v-row class="ma-0 px-1 py-2 flex-nowrap" style="max-width: 170px">
                  <v-sheet class="pa-0" color="transparent" elevation="0">
                    <span class="mr-4 fz-13 fw-600 white--text">{{ order.order.rating.collaborationValue }}</span>
                  </v-sheet>
                  <v-sheet class="pa-0" color="transparent" elevation="0">
                    <span class="fz-13 fw-600 white--text">Collaboration with designer</span>
                  </v-sheet>
                </v-row>
              </v-tooltip>
              <v-tooltip v-if="order.order.rating.quality" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    v-on="on"
                    max-width="33"
                    max-height="33"
                    :src="order.order.rating.quality === 'GOOD' ? require('@/assets/img/rate5.png') : order.order.rating.quality === 'OK' ? require('@/assets/img/rate3.png') : require('@/assets/img/rate1.png')"></v-img>
                </template>
                <v-row class="ma-0 px-1 py-2 flex-nowrap" style="max-width: 170px">
                  <v-sheet class="pa-0" color="transparent" elevation="0">
                    <span class="mr-4 fz-13 fw-600 white--text">{{ order.order.rating.qualityValue }}</span>
                  </v-sheet>
                  <v-sheet class="pa-0" color="transparent" elevation="0">
                    <span class="fz-13 fw-600 white--text">Design quality</span>
                  </v-sheet>
                </v-row>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-col>
<!--    order view -->
    <v-dialog
      v-model="editOrderShow"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="z-index-400"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
    >
      <EditOrder
        :order.sync="editOrder"
        :dialog.sync="editOrderShow"
        :readOnly.sync="readOnly"
        @getOrders="getMyOrders"
        ref="editOrder"
      />
    </v-dialog>
<!--    Edit profile info -->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      id="designer-info"
      class="designer-info pt-20"
      style="height: 100vh"
      v-model="editProfileShow"
    >
      <edit-profile
        :editProfile="editProfile"
        :imgSrc.sync="imgSrc"
        @closeEditProfile="closeEditProfile"
        @getMyInfo="getMyInfo"
      />
    </v-navigation-drawer>
    <v-overlay
      :z-index="0"
      :value="editProfileShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="closeEditProfile"
    />
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-row>
</template>

<script>
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
import numberFormat from '@/helpers/numberFormat'
import EditOrder from '@/components/App/Orders/EditOrder'
import EditProfile from '@/components/App/MyProfile/EditProfile'
import { statusColor } from '@/helpers/staticColors'
import VSnackbars from 'v-snackbars'
const locale = new Locale()

export default {
  name: 'MyProfile',
  components: { EditProfile, EditOrder, 'v-snackbars': VSnackbars },
  props: ['new'],
  filters: {
    numberFormat
  },
  data () {
    return {
      designerInfo: null,
      orders: null,
      editOrderShow: false,
      editOrder: null,
      readOnly: true,
      editProfileShow: false,
      editProfile: null,
      imgSrc: null,
      waitEdit: false,
      messages: []
    }
  },
  methods: {
    color (value) {
      return statusColor(value)
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    edit () {
      this.readOnly = false
    },
    closeEditProfile() {
      this.editProfileShow = false
    },
    showEditProfile() {
      if (this.designerInfo) {
        this.editProfile = JSON.parse(JSON.stringify(this.designerInfo))
        this.imgSrc = this.designerInfo.avatarUrl
        this.editProfileShow = true
      } else this.waitEdit = true
    },
    async getMyInfo () {
      await this.$apollo.query({
        query: gql`query getMyInfo ($id: ID! ) {
          team {
            designer(id: $id) {
              avatarUrl
              businessInitials
              avatarUploadUrl
              businessName
              roleRate {
                roleName
                occupation
              }
              skillLevel {
                name
                id
              }
              contact
              id
              gmail
              portfolio
              tools {
                name
                id
                iconUrl
              }
              statistics {
                onTimeDelivery
                rank
                qualityRank
                collaborationRank
              }
            }
          }
        }`,
        variables: {
          id: this.me.designerUser.id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.designerInfo = data.data.team.designer
        this.getMyOrders()
        if (this.waitEdit) this.showEditProfile()
        this.waitEdit = false
      }).catch((err) => {
        console.log(err)
      })
    },
    async getMyOrders () {
      await this.$apollo.query({
        query: gql`query getMyOrders($input: DashboardInput!, $id: ID!) {
          dashboard {
            dashboardStatistics(input: $input, id: $id) {
              data {
                cost
                id
                order {
                  name
                  number
                  totalCost
                  totalDesignerCost
                  totalDeveloperCost
                  currency
                  dateEnd
                  dateEndPrediction
                  manager {
                    businessName
                  }
                  client {
                    name
                  }
                  rating {
                    collaboration
                    collaborationValue
                    qualityValue
                    quality
                  }
                  status {
                    value
                    label
                    id
                  }
                  paymentDone
                }
                rating {
                  qualityValue
                  quality
                  collaborationValue
                  collaboration
                }
                id
              }
            }
          }
        }`,
        // Parameters
        variables: {
          id: this.me.designerUser.id,
          input: {
            periodStart: '2000-01-01',
            periodEnd: '2900-01-01'
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.orders = data.data.dashboard.dashboardStatistics.data
        this.designerInfo.statistics.qualityValue = data.data.dashboard.dashboardStatistics.data.rating.qualityValue
        this.designerInfo.statistics.collaborationValue = data.data.dashboard.dashboardStatistics.data.rating.collaborationValue
      }).catch((err) => {
        console.log(err)
      })
    },
    async getOrder (id) {
      await this.$apollo.query({
        query: gql`query getOrder ($id: ID! ) {
            orders {
              order(id: $id) {
                authorSupervision
                createdAt
                dateEndPrediction
                dateEnd
                dateStart
                type
                figmaUrl
                googleDocUrl
                jiraUrl
                niche {
                  id
                  name
                }
                client {
                  id
                  name
                  telegram
                  phone
                  company {
                    id
                    name
                  }
                  channel {
                    id
                    name
                  }
                }
                currency
                currencySymbol
                exchangeRates {
                  rub
                  usd
                }
                difficulty {
                  id
                  name
                }
                expirationDate
                feeRate
                id
                pricingExplanationUrl
                manager {
                  avatarUrl
                  id
                  businessInitials
                  businessName
                }
                mobile
                name
                number
                paymentType {
                  name
                  id
                }
                prototype
                status {
                  label
                  value
                  id
                }
                totalCost
                totalSalary
                totalDesignerCost
                totalDesignerSalary
                totalDeveloperCost
                totalDeveloperSalary
                totalProfit
                updatedAt
              }
            }
        }`,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.readOnly = true
        this.editOrder = data.data.orders.order
        this.editOrderShow = true
        const self = this
        setTimeout(function () {
          self.$refs.editOrder.resetPage()
          self.$refs.editOrder.resetItems()
        }, 0)
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  mounted() {
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        if (this.new) {
          this.showEditProfile()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
